import { USER_REQUEST, USER_ERROR, USER_SUCCESS, USER_UPDATE_PROFIL } from "../actions/user";
import { AUTH_LOGOUT, AUTH_SUCCESS } from "../actions/auth";
import axios from "axios";
import jwtService from "@/service/jwt";

const state = { 
    status: "", 
    profile: {},
    roles: [],
    force_password_reset: false
};

const getters = {
    getProfile: (state) => state.profile,
    getRoles: (state) => state.roles,
    isProfileLoaded: (state) => !!state.profile.nom,
    isPasswordReset: (state) => state.force_password_reset,
};

const actions = {
    [USER_REQUEST]: ({ commit, dispatch }) => {
        // commit(USER_REQUEST);
        const jwt = localStorage.getItem("user-token");
       
        if (jwt) {
            commit(AUTH_SUCCESS, {'token': jwt})        
            const jwtPayload = jwtService.parseJwt(jwt);
            state.roles = jwtPayload.roles;
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + jwt
            return axios.get(process.env.VUE_APP_API +"/client/me", {}).then(resp => {            
                commit(USER_SUCCESS, resp.data);
                return resp.data;
            }).catch(() => {
                commit(USER_ERROR);
                // if resp is unauthorized, logout, to
                dispatch(AUTH_LOGOUT);
            });
        }else{
            commit(USER_ERROR);
            // if resp is unauthorized, logout, to
            dispatch(AUTH_LOGOUT);
        }
   },
   [USER_UPDATE_PROFIL]: ({ commit }, user) => {
    return new Promise((resolve, reject) => {
        commit(USER_REQUEST);        
        axios.put(process.env.VUE_APP_API +"/client/edit", user).then(resp => {
            resolve(resp.data);
        }).catch(err => {
            console.log("ERROR", err)
            commit(USER_ERROR, err);
            reject(err);
        });
    });
},
};

const mutations = {
    [USER_REQUEST]: (state) => {
        state.status = "loading";
    },
    [USER_SUCCESS]: (state, resp) => {        
        state.profile = resp
        state.force_password_reset = resp.force_password_reset
        state.status = "success";
    },
    [USER_ERROR]: (state) => {
        state.status = "error";
    },
    [AUTH_LOGOUT]: (state) => {
        state.profile = {};
    }
};

export default {
    state,
    getters,
    actions,
    mutations
};