import { createRouter, createWebHistory } from "vue-router";
import store from '../store/main';
import Home from '../views/Home.vue'
import userService from '@/service/user'

const ifNotAuthenticated = (to, from, next) => {
  if (!store.getters.isAuthenticated) {
    next();
    return;
  }
  next("/");
};

const ifAuthenticated = (to, from, next) => {
  if (store.getters.isAuthenticated) {    
    if (store.getters.isPasswordReset && to.name != "profil") {
      next("/profil");
      return;
    }
    next();
    return;
  }
  next("/login");
  return;
};




const routes = [
  {
    path: '/',
    redirect: '/home',
  },
  {
    path: '/home',
    name: 'home',
    component: Home,
    beforeEnter: ifAuthenticated
  },
  {
    path: '/login',
    name: 'login',
    meta: {layout : "simple"},
    component: () => import('../views/Login.vue'),
    beforeEnter: ifNotAuthenticated
  },
  {
    path: '/forgot-password',
    name: 'forgot_password',
    meta: {layout : "simple"},
    component: () => import('../views/ForgotPassword.vue'),
    beforeEnter: ifNotAuthenticated
  },
  {
    path: '/etablissements',
    name: 'etablissements',
    meta: {authorize: ["ROLE_VENDEUR", "ROLE_SUPER_ADMIN"] },
    component: () => import('../views/Etablissements.vue'),
    beforeEnter: ifAuthenticated
  },
  {
    path: '/etablissement/:uuid',
    name: 'etablissement',
    component: () => import('../views/Etablissement.vue'),
    beforeEnter: ifAuthenticated
  },
  {
    path: '/etablissement/calendrier/:uuid',
    name: 'etablissement_calendrier',
    component: () => import('../views/EtablissementCalendrier.vue'),
    beforeEnter: ifAuthenticated
  },
  {
    path: '/offres',
    name: 'offres',
    component: () => import('../views/Offres.vue'),
    beforeEnter: ifAuthenticated
  },
  {
    path: '/offre/create/:uuid?',
    name: 'offre_create',
    component: () => import('../views/OffreCreator.vue'),
    beforeEnter: ifAuthenticated
  },
  {
    path: '/offre/:uuid?',
    name: 'offre',
    component: () => import('../views/Offre.vue'),
    beforeEnter: ifAuthenticated
  },
  {
    path: '/produits',
    name: 'produits',
    component: () => import('../views/Produits.vue'),
    beforeEnter: ifAuthenticated
  },
  {
    path: '/produit/:uuid?',
    name: 'produit',
    component: () => import('../views/Produit.vue'),
    beforeEnter: ifAuthenticated
  },
  {
    path: '/produit_import',
    name: 'produit_import',
    component: () => import('../views/ProduitImport.vue'),
    beforeEnter: ifAuthenticated
  },
  {
    path: '/experiences',
    name: 'experiences',
    component: () => import('../views/Experiences.vue'),
    beforeEnter: ifAuthenticated
  },
  {
    path: '/experience/:uuid?',
    name: 'experience',
    component: () => import('../views/Experience.vue'),
    beforeEnter: ifAuthenticated
  },
  {
    path: '/commandes',
    name: 'commandes',
    component: () => import('../views/Commandes.vue'),
    beforeEnter: ifAuthenticated
  },
  {
    path: '/commande/:uuid?',
    name: 'commande',
    component: () => import('../views/Commande.vue'),
    beforeEnter: ifAuthenticated
  },
  {
    path: '/boutiques',
    name: 'boutiques',
    component: () => import('../views/Boutiques.vue'),
    beforeEnter: ifAuthenticated
  },
  {
    path: '/reservations',
    name: 'reservations',
    component: () => import('../views/Reservations.vue'),
    beforeEnter: ifAuthenticated
  },
  {
    path: '/profil',
    name: 'profil',
    component: () => import('../views/Profil.vue'),
    beforeEnter: ifAuthenticated
  },
]



const router = createRouter({
  history: createWebHistory(),
  routes: routes,
});


router.beforeEach((to, from, next) => {
  // redirect to login page if not logged in and trying to access a restricted page
  const { authorize } = to.meta;  
  if(authorize) {
    if(!userService.hasRole(authorize)) {
      return next({ path: '/'});
    }  
  }

  next();
})
export default router
