<template>
  <component :is='layout'>
    <router-view></router-view>
  </component>
</template>

<script>
import { USER_REQUEST } from "./store/actions/user";
import { mapGetters } from "vuex";

const default_layout = "default";

export default {
  name: 'App',
  components: {
  },
  computed: {
    ...mapGetters(["getProfile", "isProfileLoaded"]),
    layout() {
      return (this.$route.meta.layout || default_layout) + '-layout';
    }
  },  
  mounted : function(){
    this.$store.dispatch(USER_REQUEST).then(profile =>{
      console.log("USER LOADED", profile)
    })
    this.$store.dispatch('init')
  }
}
</script>

<style>

</style>
